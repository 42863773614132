.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure navbar is above other content */
  box-shadow: 0 2px 5px rgba(0,0,0,.3); /* Optional: Adds a shadow for better visibility */
  transition: all 0.3s; /* Smooth transition for sticky effect */
}

/* Optionally, animate the navbar entering from above */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.sticky.animate {
  animation: slideDown 0.3s forwards;
}

.fixed-top {
  transition: top 0.3s;
  width: 100%;
  z-index: 1030; /* Ensure navbar stays on top */
}

